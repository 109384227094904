import { loadConfig } from "alpha-gpt-config"

loadConfig().then((config) => {
  window.config = config
  // 替换favicon
  const newFavicon = document.createElement("link")
  newFavicon.rel = "shortcut icon"
  newFavicon.href = config.favicon || "favicon.ico"
  document.head.appendChild(newFavicon)
  // 加载应用
  import("./bootstrap")
  // 替换title
  document.title = config.title || "法律智能助手"
})
